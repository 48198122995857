<div class="limiter">
  <div class="container-login100" style="background-image: url('assets/img/bg-01.jpg');">
    <div class="wrap-login100">
      <div class="login100-form validate-form">
        <span class="login100-form-logo mb-3">
          <img src="assets/img/logo.png" width="110px">
        </span>

        <span class="login100-form-title p-b-34 p-t-27">
          Recuperar contraseña
        </span>

        <div class="wrap-input100 validate-input mt-5">
          <input class="input100" type="password" placeholder="Contraseña" id="password" [(ngModel)]="password"
            autocomplete="off">
          <span class="focus-input100" data-placeholder="&#xf190;"></span>
        </div>

        <div class="wrap-input100 validate-input">
          <input class="input100" type="password" placeholder="Confirmar contraseña" id="confirmPassword" [(ngModel)]="confirmPassword"
            autocomplete="off">
          <span class="focus-input100" data-placeholder="&#xf190;"></span>
        </div>

        <div class="container-login100-form-btn">
          <button class="login100-form-btn" (click)="changePassword()">
            Recuperar
          </button>
        </div>

      </div>
    </div>
  </div>
</div>