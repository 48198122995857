import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { loadingFireToast } from 'src/assets/js/toast.js';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {

  tokenconfirmEmail: string;

  constructor(private activatedRoute: ActivatedRoute, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.tokenconfirmEmail = params.conf;
    });
    const loading = loadingFireToast('Confirmando email, por favor espere...');
    this.authService.confirmEmail(this.tokenconfirmEmail).subscribe(d => {
      loading.close();
      if (d) {
        Swal
          .fire({
            title: 'Exito',
            text: 'Email verificado correctamente!',
            timer: 2000,
          })
          .then(() => {
            this.router.navigate(['/']);
          });
      }
    },
      (error) => {
        console.log('error confirmando el email', error);
        loading.close();
      });
  }

}
