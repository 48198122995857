<div class="row">
  <div class="col-12 col-md-12 col-lg-12">
    <div class="card">
      <div class="card-header">
        <button class="btn btn-lg btn-outline-primary mr-2" (click)="goBack()" *ngIf="service.getFilter$">
          <i class="fas fa-arrow-left"></i>
        </button>
        <h4>Reporte de visitas</h4>
      </div>
      <div class="card-body">
        <div *ngIf="!service.getFilter$">
          <div class="row mb-3">
            <div class="col-md-6">
              <label>Fecha Inicio</label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon1"><i class="fa fa-calendar-alt"></i></span>
                <input type="date" class="form-control" aria-describedby="addon-wrapping" [(ngModel)]="from">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" (click)="from = '';"><i
                      class="fa fa-times"></i></button>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label>Fecha Fin</label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon1"><i class="fa fa-calendar-alt"></i></span>
                <input type="date" class="form-control" aria-describedby="addon-wrapping" [(ngModel)]="to">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" (click)="to = '';"><i
                      class="fa fa-times"></i></button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-4">
              <label class="form-label">Sector</label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon1"><i class="fas fa-city"></i></span>
                <select class="form-control" [(ngModel)]="sector" id="sector" (ngModelChange)="listHome()">
                  <option value="">Seleccionar sector</option>
                  <option *ngFor="let sector of listaSectores" [value]="sector.name">{{ sector.name }}
                  </option>
                </select>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" (click)="sector = '';"><i
                      class="fa fa-times"></i></button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <label class="form-label">Casa</label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon1"><i class="fas fa-home"></i></span>
                <select class="form-control" [(ngModel)]="home" id="home" (ngModelChange)="listHome()">
                  <option value="">Seleccionar casa</option>
                  <option *ngFor="let home of listaCasaSector" [value]="home.name">{{ home.name }}
                  </option>
                </select>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" (click)="home = '';"><i
                      class="fa fa-times"></i></button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <label class="form-label">Estado</label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon1"><i class="fas fa-check"></i></span>
                <select class="form-control" [(ngModel)]="estado" id="estado">
                  <option value="0">Seleccionar estado</option>
                  <option value="1">Aceptado - Ingresó</option>
                  <option value="2">Aceptado - No ingresó</option>
                  <option value="3">Rechazado</option>
                  <option value="4">Pendiente</option>
                </select>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" (click)="estado = 0;"><i
                      class="fa fa-times"></i></button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-lg btn-outline-primary float-right mt-3" (click)="searchVisit()">
                <i class="fa fa-search"></i> Filtrar
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="service.getFilter$">
          <div class="form-group form-inline">
            Buscar: <input class="form-control ml-2" type="text" name="searchTerm" [(ngModel)]="service.searchTerm"
              autocomplete="off" />
            <span class="ml-3" *ngIf="service.loading$ | async">Cargando...</span>
            <!-- <button class="btn btn-lg btn-outline-success ml-auto" (click)="exportToExcel()">
              <i class="fa fa-file-excel"></i>
            </button> -->
          </div>

          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">Visitante</th>
                <!-- <th scope="col">Email</th>
                <th scope="col">Celular</th> -->
                <th scope="col">Sector</th>
                <th scope="col">Casa</th>
                <th scope="col" class="text-center">Fecha</th>
                <th scope="col">Ver</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let visita of VisitFilter | async">
                <td>{{ visita.name }}</td>
                <!-- <td>{{ visita.email }}</td>
                <td>{{ visita.phone }}</td> -->
                <td>{{ visita.sector }}</td>
                <td>{{ visita.home }}</td>
                <td class="text-right">{{ visita.visitDate | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
                <td>
                  <button class="btn btn-md btn-outline-primary" (click)="detailVisit(visita, visitDetail)">
                    <i class="fa fa-eye"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize"
              [boundaryLinks]="true" [maxSize]="5">
            </ngb-pagination>

            <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
              <option [ngValue]="20">20 visitas por página</option>
              <option [ngValue]="50">50 visitas por página</option>
              <option [ngValue]="100">100 visitas por página</option>
            </select>
          </div>
        </div>
      </div>
      <div class="card-footer text-right"></div>
    </div>
  </div>
</div>

<ng-template #visitDetail let-modal>
  <!-- <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"><strong>Datos adicionales</strong></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> -->
  <div class="modal-body">
    <button type="button" class="btn btn-outline-primary btn-lg btn-block m-2" (click)="collapse.toggle()"
      [attr.aria-expanded]="!collapseInfo" aria-controls="collapseExample">
      <h3>Detalle Visita</h3>
    </button>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="collapseInfo">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="row">
                <div class="col-md-12">
                  <div class="my-1">
                    <h5><strong>Visitante</strong></h5>
                    <div class="styleSubDiv">
                      <h6>{{ visitaSeleccionada.name | uppercase }}</h6>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="my-1">
                    <strong>Email</strong>
                    <div class="styleSubDiv">
                      <h6>{{ visitaSeleccionada.email | lowercase  }}</h6>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="my-1">
                    <strong>Celular</strong>
                    <div class="styleSubDiv">
                      <h6>{{ visitaSeleccionada.phone }}</h6>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="my-1">
                    <strong>Sector</strong>
                    <div class="styleSubDiv">
                      <h6>{{ visitaSeleccionada.sector  | uppercase }}</h6>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="my-1">
                    <strong>Casa</strong>
                    <div class="styleSubDiv">
                      <h6>{{ visitaSeleccionada.home | uppercase  }}</h6>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="my-1">
                    <strong>Motivo</strong>
                    <div class="styleSubDiv">
                      <h6>{{ visitaSeleccionada.motivo | uppercase  }}</h6>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="my-1">
                    <strong>Fecha</strong>
                    <div class="styleSubDiv">
                      <h6>{{ visitaSeleccionada.visitDate | date: 'yyyy-MM-dd HH:mm:ss' }}</h6>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="my-1">
                    <strong>Estado</strong>
                    <div class="styleSubDiv">
                      <h6>{{ visitaSeleccionada.estado | uppercase  }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="row justify-content-center pt-2" *ngIf="visitaSeleccionada.video">
                <div class="my-1">
                  <h5><strong>Video</strong></h5>
                  <div class="pt-2">
                    <video controls width="auto" height="220px" preload="auto" class="view-video" crossorigin>
                      <source src="{{visitaSeleccionada.video}}" type="{{mime}}">
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
  </div>
</ng-template>