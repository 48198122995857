import { Injectable, OnInit, PipeTransform } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { fireToast, loadingFireToast } from 'src/assets/js/toast.js';
import { Visit } from '../model/visit';
import { ApiService } from './api.service';

interface SearchResult {
  datos: Visit[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(visitas: Visit[]): Visit[] {
  return visitas;
}

function matches(match: Visit, term: string, pipe: PipeTransform) {
  return match.name.toLowerCase().includes(term.toLowerCase())
    || match.name.toLowerCase().includes(term.toLowerCase())
    || match.email.toLowerCase().includes(term.toLowerCase())
    || match.phone.toLowerCase().includes(term.toLowerCase())
    || match.sector.toLowerCase().includes(term.toLowerCase())
    || match.home.toLowerCase().includes(term.toLowerCase())
}

@Injectable({ providedIn: 'root' })
export class VisitService implements OnInit {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _visitas$ = new BehaviorSubject<Visit[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private _dataServicio: Array<Visit> = [];
  private _filter$: boolean = false;
  private _getAllData$ = new BehaviorSubject<Visit[]>([]);

  private _state: State = {
    page: 1,
    pageSize: 20,
    searchTerm: ''
  };

  constructor(private pipe: DecimalPipe, private apiService: ApiService) {

    this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._visitas$.next(result.datos);
      this._total$.next(result.total);
    });

    this._search$.next();
  }

  ngOnInit() {
  }

  async searchVisit(params: any) {
    const loading = loadingFireToast('Consultando visitas, por favor espere...');
    await this.apiService.get('visita/list-visit/filterBy?from=' + params.from + '&to=' + params.to + '&sector=' + params.sector + '&home=' + params.home + '&estado=' + params.estado).subscribe(
      (res) => {
        console.log('visitas', res.data);
        this._dataServicio = res.data;
        loading.close();
        if (!this._dataServicio || this._dataServicio.length <= 0) {
          fireToast('info', 'No se encontraron visitas');
          this._filter$ = false;
        } else {
          this._getAllData$.next(res.data);
          this._search$.next();
          this._filter$ = true;
        }
      },
      (error) => {
        loading.close();
        console.log('error consultando las visitas', error);
      }
    );
  }

  get getVisitas$() { return this._visitas$.asObservable(); }
  get allData() { return this._getAllData$.asObservable() };
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  get getFilter$() { return this._filter$; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: string) { this._set({ searchTerm }); }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const { pageSize, page, searchTerm } = this._state;

    // 1. sort
    let datos = sort(this._dataServicio);

    // 2. filter
    datos = datos.filter(dato => matches(dato, searchTerm, this.pipe));
    const total = datos.length;

    // 3. paginate
    datos = datos.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ datos, total });
  }

  backFilter() {
    this._filter$ = false;
  }
}