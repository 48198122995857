<div class="limiter">
  <div class="container-login100" style="background-image: url('assets/img/bg-01.jpg');">
    <div class="wrap-singup100">
      <div class="login100-form validate-form">
        <span class="login100-form-logo">
          <i class="zmdi zmdi-account"></i>
        </span>

        <span class="login100-form-title pt-3 pb-3">
          Información de contacto
        </span>

        <div class="row pt-3">
          <div class="col-md-6">
            <div class="wrap-input100">
              <input class="input100" type="text" placeholder="Sector" id="sector" [(ngModel)]="resident.sectorName"
                autocomplete="off" disabled>
              <span class="focus-input100" data-placeholder="&#xf133;"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="wrap-input100">
              <input class="input100" type="text" placeholder="Nombre/Número de casa" id="casa"
                [(ngModel)]="resident.homeName" autocomplete="off" disabled>
              <span class="focus-input100" data-placeholder="&#xf175;"></span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="wrap-input100 mt-2">
              <input class="input100" type="text" placeholder="Nombre residente principal" id="name"
                [(ngModel)]="resident.name" autocomplete="off" disabled>
              <span class="focus-input100" data-placeholder="&#xf207;"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="wrap-input100 mt-2">
              <input class="input100" type="text" placeholder="Apellido residente principal" id="lastname"
                [(ngModel)]="resident.lastName" autocomplete="off" disabled>
              <span class="focus-input100" data-placeholder="&#xf207;"></span>
            </div>
          </div>
        </div>

        <div class="row">
          <!-- <div class="col-md-6">
            <div class="wrap-input100 mt-2">
              <input class="input100" type="email" placeholder="Email residente principal" id="email"
                autocomplete="off">
              <span class="focus-input100" data-placeholder="&#xf15a;"></span>
            </div>
          </div> -->
          <div class="col-md-6">
            <div class="wrap-input100 mt-2">
              <input class="input100" type="text" placeholder="Celular de contacto" id="phone"
                [(ngModel)]="resident.phone" autocomplete="off" [disabled]="update">
              <span class="focus-input100" data-placeholder="&#xf2be;"></span>
            </div>
          </div>
          <div class="col-md-12">
            <p class="politica">
              * Al dar clic en el boton <b>Actualizar</b> esta aceptando nuestra <a href="/politica" target="_blank">Politica de tratamiento de datos y privacidad</a>
            </p>
            <br/>
          </div>
        </div>

        <div class="container-login100-form-btn" *ngIf="!update">
          <button class="login100-form-btn" (click)="updateResident()">
            Actualizar
          </button>
        </div>

      </div>
    </div>
  </div>
</div>