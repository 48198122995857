import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor() { }

  async uploadFile(file, idBuilding) {
    const contentType = file.type;
    const bucket = new S3({
      accessKeyId: environment.AWSAccessKeyID,
      secretAccessKey: environment.AWSSecretKey,
      region: 'us-east-2'
    });

    const params = {
      Bucket: environment.AWSBucket,
      Key: `${idBuilding}/${file.name}`,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };

    try {
      const load = await bucket.upload(params).promise();
      return load;
    } catch (e) {
      console.log('error subiendo el video de la visita al s3', e);
      return false;
    }

    // bucket.upload(params, function (err, data) {
    //   if (err) {
    //     console.log('There was an error uploading your file: ', err);
    //     return false;
    //   }
    //   console.log('Successfully uploaded file.', data);
    //   return true;
    // });
  }

}
