import { Component, OnInit } from '@angular/core';
import { DynamicScriptLoaderService } from './services/dynamic-script-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DynamicScriptLoaderService]
})
export class AppComponent implements OnInit {
  title = 'veciino-backoffice';

  constructor(private dynamicScriptLoader: DynamicScriptLoaderService) { }

  ngOnInit(): void {
    // this.loadScripts();
  }

  private loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoader.load('nicescroll', 'scripts', 'custom', 'stisla').then(data => {
      // Script Loaded Successfully
    }).catch(error => console.log(error));
  }
}
