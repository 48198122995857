import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { loadingFireToast } from 'src/assets/js/toast.js';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  tokenTemp: string;
  password: string;
  confirmPassword: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.tokenTemp = params.reset;
    });
  }

  changePassword() {
    if (!this.password) {
      Swal.fire('Confirmación', 'El campo contraseña es requerido', 'info');
      return;
    }
    if (!this.confirmPassword) {
      Swal.fire('Confirmación', 'La confirmación de la contraseña es requerida', 'info');
      return;
    }
    if (this.password !== this.confirmPassword) {
      Swal.fire('Confirmación', 'Las contraseñas no coinciden', 'info');
      return;
    }
    const loading = loadingFireToast('Cambiando contraseña, por favor espere...');
    this.authService.changePassword(this.password, this.tokenTemp).subscribe(d => {
      loading.close();
      if (d) {
        Swal
          .fire({
            icon: 'success',
            title: 'Exito',
            text: 'Contraseña cambiada correctamente!',
            timer: 2000,
          })
          .then(() => {
            this.router.navigate(['/app']);
          });
      }
    },
      (error) => {
        loading.close();
        console.log('error cambiando la contraseña', error);
        Swal
          .fire({
            icon: 'warning',
            title: 'Error',
            text: 'Su contraseña no se ha cambiado correctamente, debe tener al menos 8 caracteres, con mayúsculas y minúscula, un número y al menos un carácter especial como *$%#@?!',
            timer: 10000,
          })

      });
  }

}
