import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Login } from '../model/login';
import { LoginResponse } from '../model/login-response';
import { Admin } from '../model/admin';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<Admin>;
  public currentUser: Observable<Admin>;
  private currentLoginSubject: BehaviorSubject<LoginResponse>;
  public currentLogin: Observable<LoginResponse>;
  public auth = false;

  constructor(private http: HttpClient) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    this.currentUserSubject = new BehaviorSubject<Admin>(currentUser ? currentUser : '{}');
    this.currentUser = this.currentUserSubject.asObservable();
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    this.currentLoginSubject = new BehaviorSubject<LoginResponse>(token ? token : '{}');
    this.currentLogin = this.currentLoginSubject.asObservable();
  }

  public get currentUserValue(): Admin {
    return this.currentUserSubject.value;
  }

  public set currentUserValue(user: Admin) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  public get currentLoginValue(): LoginResponse {
    return this.currentLoginSubject.value;
  }

  public get isAuth(): boolean {
    const token = JSON.parse(localStorage.getItem('token') || null);
    this.currentLoginSubject = new BehaviorSubject<LoginResponse>(token ? token : null);
    // console.log(this.currentLoginSubject.value);
    return this.currentLoginSubject.value !== null;
  }

  register(admin: Admin) {
    return this.http.post<any>(`${environment.url}auth/signup`, admin).pipe(map(d => d));
  }

  confirmEmail(tokenconfirmEmail: string) {
    return this.http.get<any>(`${environment.url}auth/confirmation/${tokenconfirmEmail}`).pipe(map(d => d));
  }

  login(login: Login) {
    return this.http.post<LoginResponse>(`${environment.url}auth/login`, login)
      .pipe(
        map(d => {
          if (d.status) {
            this.auth = true;
            localStorage.setItem('token', JSON.stringify(d.data.token));
            this.currentLoginSubject.next(d);
          }
          return d.status;
        }, (error: any) => {
          console.log('error al realizar el login', error);
          return false;
        })
      );
  }

  resetPassword(email: string) {
    return this.http.post<any>(`${environment.url}auth/recover-password`, { email: email })
      .pipe(
        map(d => {
          return d;
        }));
  }

  changePassword(password: string, token: string) {
    return this.http.post<any>(`${environment.url}auth/change-password/${token}`, { password: password })
      .pipe(
        map(d => {
          console.log('d service', d);
          if (d.status) {
            this.auth = true;
            localStorage.setItem('token', JSON.stringify(d.data.token));
            this.currentLoginSubject.next(d);
          }
          return d.status;
        }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    this.currentUserSubject.next(new Admin);
    this.currentLoginSubject.next(new LoginResponse);
  }
}
