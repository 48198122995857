import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Admin } from '../model/admin';
import { AuthService } from '../services/auth.service';
import { loadingFireToast } from 'src/assets/js/toast.js';
import Swal from 'sweetalert2';
import { ApiService } from '../services/api.service';
import { Department } from '../model/department';
import { City } from '../model/city';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  admin: Admin = {
    name: '',
    address: '',
    department: '',
    city: '',
    adminPhone: '',
    password: '',
    adminName: '',
    adminLastName: '',
    adminEmail: '',
    nombre: ''
  };
  validar: boolean = false;
  departamentos: Array<Department> = [];
  ciudades: Array<City> = [];

  constructor(private router: Router, private authService: AuthService, private apiService: ApiService) { }

  ngOnInit(): void {
    this.apiService.get('departamento').subscribe(
      (res) =>{
        this.departamentos = res.data;
      },
      (error) => {
        console.log('error consultando los departamentos', error);
      }
    );
  }

  findCities() {
    if (this.admin.department) {
      this.apiService.get('departamento/' + this.admin.department).subscribe(
        (res) => {
          this.ciudades = res.data;
        },
        (error) => {
          console.log('error consultando ciudades por departamento', error);
        }
      )
    }
  }

  registerAdmin() {
    if (!this.validateAdmin()) {
      return;
    }
    const loading = loadingFireToast('Creando registro, por favor espere...');
    this.authService.register(this.admin).subscribe(
      (res) => {
        loading.close();
        console.log('res', res);
        Swal
          .fire({
            icon: 'success',
            title: 'Exito',
            text: 'Registro realizado correctamente, debes validar tu correo.',
            timer: 10000,
          })
          .then(() => {
            this.router.navigate(['/']);
          });
      },
      (error) => {
        loading.close();
        this.fireToast('Ha ocurrido un error al registrar el edificio, '+error);
        console.log('error en el registro', error);
      }
    );
  }

  validateAdmin() {
    if (!this.validar) {
      this.validar = true;
      this.validate(this.validar, this.admin.name, 'El nombre del administrador es requerido.', 'name');
      this.validate(this.validar, this.admin.address, 'La dirección es requerida.', 'address');
      this.validate(this.validar, this.admin.department, 'El departamento es requerido.', 'department');
      this.validate(this.validar, this.admin.city, 'La ciudad es requerida.', 'city');
      this.validate(this.validar, this.admin.adminPhone, 'El teléfono del administrador es requerido.', 'adminPhone');
      this.validate(this.validar, this.admin.password, 'La contraseña es requerida.', 'password');
      this.validate(this.validar, this.admin.adminName, 'El nombre del administrador es requerido.', 'adminName');
      this.validate(this.validar, this.admin.adminLastName, 'El apellido del administrador es requerido.', 'adminLastName');
      this.validate(this.validar, this.admin.adminEmail, 'El correo del administrador es requerido.', 'adminLastName');
      this.validate(this.validar, /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.admin.adminEmail), 'El correo del administrador no es válido.', 'adminLastName');
    }
    return this.validar;
  }

  validate(validate, condition, msg, id) {
    if (validate) {
      if (!condition) {
        this.validar = false;
        this.fireToast(msg);
        this.showField(id);
        return false;
      }
    }
  }

  fireToast(msg) {
    Swal.fire('Confirmación', msg, 'info');
  }

  showField(id) {
    const element = document.getElementById(id);
    const properties = element.getBoundingClientRect();
    window.scrollTo({
      top: properties.y,
      behavior: 'smooth'
    });
    document.getElementById(id).focus();
  }

}
