<div class="limiter">
  <div class="container-login100" style="background-image: url('assets/img/bg-01.jpg');">
    <div class="wrap-singup100">
      <div class="login100-form validate-form">
        <span class="login100-form-logo">
          <i class="zmdi zmdi-account"></i>
        </span>

        <span class="login100-form-title pt-3 pb-3">
          Datos visitante
        </span>

        <div class="row">
          <div class="col-md-6">
            <div class="wrap-input100">
              <input class="input100" type="text" [(ngModel)]="name" disabled>
              <span class="focus-input100" data-placeholder="&#xf207;"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="wrap-input100">
              <input class="input100" type="text" [(ngModel)]="phone" disabled>
              <span class="focus-input100" data-placeholder="&#xf2be;"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="wrap-input100">
              <input class="input100" type="text" [(ngModel)]="email" disabled>
              <span class="focus-input100" data-placeholder="&#xf15a;"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="wrap-input100">
              <input class="input100" type="text" [(ngModel)]="reason" disabled>
              <span class="focus-input100" data-placeholder="&#xf20b;"></span>
            </div>
          </div>
        </div>

        <div class="row justify-content-center pb-4">
          <div class="col-md-12 text-center pb-2">
            <span style="color: white; font-family: Poppins-Regular; font-size: 16px;"> <i
                class="zmdi zmdi-videocam"></i> Video {{ reason }}</span>
          </div>
          <video controls width="auto" height="220px" preload="auto" crossorigin>
            <source src="{{video}}" type="{{mime}}">
          </video>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="wrap-input100">
              <select class="input100" style="border: 0px !important;" [(ngModel)]="acceptVisit" (ngModelChange)="changeResponseVisit()">
                <option value="" class="option">¿Desea recibir la visita?</option>
                <option value="SI" class="option">SI</option>
                <option value="NO" class="option">NO</option>
              </select>
              <span class="focus-input100" data-placeholder="&#xf26b;"></span>
            </div>
          </div>
          <div class="col-md-6" *ngIf="acceptVisit === 'NO'">
            <div class="wrap-input100">
              <input class="input100" type="text" [(ngModel)]="reasonDenay" placeholder="Por favor escriba el motivo">
              <span class="focus-input100" data-placeholder="&#xf136;"></span>
            </div>
          </div>
        </div>

        <div class="container-login100-form-btn">
          <button class="login100-form-btn" (click)="sendResponseVisit()">
            Enviar
          </button>
        </div>

      </div>
    </div>
  </div>
</div>