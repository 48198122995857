<div class="row">
  <div class="col-12 col-md-12 col-lg-12">
    <div class="card">
      <div class="card-header">
        <h4>Residentes</h4><small *ngIf="listResidents.length == 0">Si no ve el listado de residentes, se debe a que el proceso de carga no ha finalizado</small>
        <button class="btn btn-danger ml-auto" (click)="deleteResidents()">
          <i class="zmdi zmdi-delete"></i> Eliminar residentes
        </button>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered table-md text-center">
            <tr>
              <th>#</th>
              <th>Sector</th>
              <th>Apto</th>
              <th>Nombre Residente</th>
              <th>Correo</th>
              <th>Celular</th>
            </tr>
            <tr *ngFor="let resident of listResidents; index as i" class="text-center tr-table">
              <th>{{ i + 1 }}</th>
              <td>{{ resident.sectorName }}</td>
              <td>{{ resident.homeName }}</td>
              <td>{{ resident.name }} {{ resident.lastName }}</td>
              <td>{{ resident.email }}</td>
              <td>{{ resident.phone ? resident.phone : '-' }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="card-footer text-right"></div>
    </div>
  </div>
</div>