<div id="app">
  <div class="main-wrapper">
    <div class="navbar-bg"></div>

    <app-header></app-header>

    <app-sidebar></app-sidebar>

    <div class="main-content">
      <section class="section">

        <router-outlet></router-outlet>

      </section>
    </div>

    <app-footer></app-footer>

  </div>
</div>