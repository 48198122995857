import { Component, OnInit } from '@angular/core';
import { Login } from '../model/login';
import { AuthService } from '../services/auth.service';
import { loadingFireToast } from 'src/assets/js/toast.js';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) { }

  login: Login = {
    email: '',
    password: ''
  }
  validar: boolean = false;
  resetPassword: boolean = false;
  email: string;

  ngOnInit(): void {
  }

  loginAdmin() {
    if (!this.validateAdmin()) {
      return;
    }
    const loading = loadingFireToast('Validando credenciales, por favor espere...');
    this.authService.login(this.login).subscribe(
      (res) => {
        loading.close();
        this.router.navigate(['/app']);
      },
      (error) => {
        loading.close();
        this.fireToast("Su usuario ó contraseña son inválidos");
        console.log('error en el login', error);
      }
    );
  }

  validateAdmin() {
    if (!this.validar) {
      this.validar = true;
      this.validate(this.validar, this.login.email, 'El email es requerido.', 'email');
      this.validate(this.validar, /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.login.email), 'El correo del administrador no es válido.', 'email');
      this.validate(this.validar, this.login.password, 'La contraseña es requerida', 'password');
    }
    return this.validar;
  }

  validate(validate, condition, msg, id) {
    if (validate) {
      if (!condition) {
        this.validar = false;
        this.fireToast(msg);
        this.showField(id);
        return false;
      }
    }
  }

  fireToast(msg) {
    Swal.fire('Confirmación', msg, 'info');
  }

  showField(id) {
    const element = document.getElementById(id);
    const properties = element.getBoundingClientRect();
    window.scrollTo({
      top: properties.y,
      behavior: 'smooth'
    });
    document.getElementById(id).focus();
  }

  passwordReset() {
    if (!this.email) {
      Swal.fire('Confirmación', 'El email a recuperar es requerido.', 'info');
      return;
    }
    const loading = loadingFireToast('Enviando enlace de recuperación...');
    this.authService.resetPassword(this.email).subscribe(d => {
      loading.close();
      if (d) {
        Swal
          .fire({
            title: 'Exito',
            text: 'Enlace de recuperación enviado al correo correctamente!',
            timer: 2000,
          })
          .then(() => {
            this.email = '';
            this.resetPassword = false;
          });
      }
    },
      (error) => {
        loading.close();
        const msg = `El correo electrónico ${this.email} no ha sido encontrado en nuestra base de datos o aún no está verificado`;
        this.fireToast(msg);
        console.log('error enviando el correo de recuperación', error);
      });
  }
}
