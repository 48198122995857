import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { CreateResidentsComponent } from './create-residents/create-residents.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ManageResidentsComponent } from './manage-residents/manage-residents.component';
import { VisitReportComponent } from './visit-report/visit-report.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [DashboardComponent, CreateResidentsComponent, ManageResidentsComponent, VisitReportComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardRoutingModule,
    QRCodeModule,
    NgbModule
  ]
})
export class DashboardModule { }
