<div class="row justify-content-center">
  <div class="col-lg-10">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="text-primary text-justify" style="font-size: 18px;">¿Como puedes registrar tus residentes de la
              forma mas
              rápida y sencilla?</div>
            <h6 class="text-justify mb-2">Crea un archivo csv con los siguientes campos requeridos:
              nombre sector, nombre / número de la casa o apartamento,
              nombre del residente principal, apellido del residente principal,
              email del residente principal. Con ello registrarás todos tus residentes masivamente,
              ingresa este archivo aquí:
            </h6>
            <i class="fas fa-file-excel text-primary mr-2" style="font-size: 18px;"></i>
            <input type="file" name="file" id="file" class="inputfile" (change)="cargarResidentes($event)"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
            <label for="file" id="label-file" style="cursor: pointer;" class="text-primary">Seleccionar archivo</label>
          </div>
          <div class="col imgBuilding text-center">
            <img src="assets/img/buildings.png" style="width: 250px;" />
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h3 class="text-primary">
          Sección ayuda <i class="zmdi zmdi-help-outline"></i>
        </h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="text-primary text-justify" style="font-size: 18px;">¿Que es un Sector en Veciino?</div>
            <h6 class="text-justify mb-2">
              Un sector es lo equivalente a la torre, interior, calle o manzana, o la forma como se ha
              sectorizado su condominio o conjunto residencial, en caso que no esté sectorizado, usted
              podrá crear un único sector llamado Principal.
            </h6>
            <div class="text-primary text-justify" style="font-size: 18px;">¿Necesitas ayuda para crear tus residentes?
            </div>
            <h6 class="text-justify mb-2">
              Te dejamos un ejemplo muy sencillo aquí: <a
                href="https://veciino-visit-video.s3.us-east-2.amazonaws.com/ejemplo1.csv" target="_blank">
                <i class="fas fa-file-excel text-primary mr-2" style="font-size: 18px;"></i></a>
            </h6>
          </div>
          <div class="col imgBuilding text-center">
            <img src="assets/img/help.jpg" style="width: 250px;" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>