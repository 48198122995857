import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { DashboardLayoutComponent } from './layout/dashboard-layout/dashboard-layout.component';
import { LoginModule } from './login/login.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './jwt.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { RegisterModule } from './register/register.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmEmailModule } from './confirm-email/confirm-email.module';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { UpdateContactInformationComponent } from './update-contact-information/update-contact-information.component';
import { QRCodeModule } from 'angularx-qrcode';
import { VisitComponent } from './visit/visit.component';
import { AcceptVisitComponent } from './accept-visit/accept-visit.component';
import { InfoVisitComponent } from './info-visit/info-visit.component';
import { PoliticaComponent } from './politica/politica.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    DashboardLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    UpdateContactInformationComponent,
    VisitComponent,
    AcceptVisitComponent,
    InfoVisitComponent,
    PoliticaComponent,
    ChangePasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    RegisterModule,
    LoginModule,
    ConfirmEmailModule,
    DashboardModule,
    FormsModule,
    ReactiveFormsModule,
    QRCodeModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
