<div class="limiter">
    <div class="container-login100" style="background-image: url('assets/img/bg-01.jpg');">
        <div class="wrap-singup100">
            <div class="politica">

                <h1>Política de privacidad</h1>
                <br />
                <p><small>Última actualización: 13 de abril de 2021</small></p>
                <p>
                    Esta Política de privacidad describe Nuestras políticas y procedimientos sobre la recopilación, uso
                    y divulgación de Su información cuando usa el Servicio y le informa sobre Sus derechos de privacidad
                    y cómo la ley lo protege.
                </p>
                <br />
                <p>
                    Usamos sus datos personales para proporcionar y mejorar el servicio. Al utilizar el Servicio, acepta
                    la recopilación y el uso de información de acuerdo con esta Política de privacidad. Esta Política de
                    privacidad se ha creado con la ayuda del Generador de políticas de privacidad.
                </p>
                <br />
                <h2>Interpretación y definiciones</h2>
                <br />
                <h3>Interpretación</h3>
                <br />
                <p>
                    Las palabras cuya letra inicial está en mayúscula tienen significados definidos en las siguientes
                    condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de que
                    aparezcan en singular o en plural.
                </p>
                <br />
                <h3>Definiciones</h3>
                <br />
                <p>
                    A los efectos de esta Política de privacidad:

                </p>
                <br />
                <p>
                    <b>Cuenta:</b> significa una cuenta única creada para que usted acceda a nuestro Servicio o partes de
                    nuestro Servicio.
                </p>


                <p>
                    <b>Aplicación:</b> significa el programa de software proporcionado por la Compañía descargado por
                    Usted en
                    cualquier dispositivo electrónico, llamado VECIINO.
                </p>
                <p>
                    <b>Compañía:</b> (denominada "la Compañía", "Nosotros", "Nos" o "Nuestro" en este Acuerdo) se refiere
                    a
                    FABIOARIAS SOFTWARE AND CONSULTING SAS, CR 17A 173.25, BOGOTA COLOMBIA.
                </p>
                <p>

                    <b>País</b> se refiere a: Colombia
                </p>
                <p>
                    <b>Dispositivo:</b> significa cualquier dispositivo que pueda acceder al Servicio, como una
                    computadora, un
                    teléfono celular o una tableta digital.
                </p>

                <p>
                    <b>Los datos personales</b> son cualquier información que se relacione con una persona identificada
                    o
                    identificable.
                </p>

                <p>
                    <b>Servicio</b> se refiere a la Aplicación.
                </p>

                <p>
                    <b>Proveedor de servicios:</b> significa cualquier persona física o jurídica que procesa los datos en
                    nombre de
                    la Compañía. Se refiere a empresas de terceros o personas empleadas por la Compañía para facilitar
                    el
                    Servicio, para proporcionar el Servicio en nombre de la Compañía, para realizar servicios
                    relacionados
                    con el Servicio o para ayudar a la Compañía a analizar cómo se utiliza el Servicio.

                </p>
                <p>
                    <b> El Servicio de redes sociales de terceros</b> se refiere a cualquier sitio web o cualquier sitio
                    web de red
                    social a través del cual un Usuario puede iniciar sesión o crear una cuenta para usar el Servicio.

                </p>
                <p>
                    <b>Los Datos de uso</b> se refieren a los datos recopilados automáticamente, ya sea generados por el
                    uso del
                    Servicio o por la propia infraestructura del Servicio (por ejemplo, la duración de una visita a la
                    página).
                </p>

                <p>
                    <b>Usted</b> se refiere a la persona que accede o utiliza el Servicio, o la empresa u otra entidad
                    legal en
                    nombre de la cual dicha persona accede o utiliza el Servicio, según corresponda.
                </p>

                <hr />
                <h2>Recopilación y uso de sus datos personales</h2>
                <br />
                <h3>Tipos de datos recopilados</h3>
                <br />
                <h4>Información personal</h4>
                <br />
                <p>
                    Mientras usamos Nuestro Servicio, podemos pedirle que nos proporcione cierta información de
                    identificación personal que pueda usarse para contactarlo o identificarlo. La información de
                    identificación personal puede incluir, entre otros:
                </p>
                <br />
                <ul>
                    <li>Dirección de correo electrónico</li>
                    <li>Nombre y apellido</li>
                    <li>Número de teléfono</li>
                    <li>Dirección, estado, provincia, código postal / código postal, ciudad</li>
                    <li>Datos de uso</li>
                </ul>
                <br />

                <h4>Datos de uso</h4>
                <br />
                <p>


                    Los datos de uso se recopilan automáticamente cuando se utiliza el servicio.
                </p>
                <br>
                <p>
                    Los datos de uso pueden incluir información como la dirección del Protocolo de Internet de su
                    dispositivo (por ejemplo, la dirección IP), el tipo de navegador, la versión del navegador, las
                    páginas
                    de nuestro Servicio que visita, la hora y fecha de su visita, el tiempo que pasó en esas páginas, el
                    dispositivo único identificadores y otros datos de diagnóstico.
                </p>
                <br />
                <p>Cuando accede al Servicio a través de un dispositivo móvil, podemos recopilar cierta información
                    automáticamente, que incluye, entre otros, el tipo de dispositivo móvil que utiliza, la
                    identificación
                    única de su dispositivo móvil, la dirección IP de su dispositivo móvil, su dispositivo móvil sistema
                    operativo, el tipo de navegador de Internet móvil que utiliza, identificadores de dispositivo únicos
                    y
                    otros datos de diagnóstico.</p>
                <br />
                <p>
                    También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o
                    cuando accede al Servicio a través de un dispositivo móvil.
                </p>

                <br />
                <h4>Información recopilada durante el uso de la aplicación</h4>
                <br />
                <p>
                    Mientras usamos nuestra aplicación, con el fin de proporcionar características de la misma,
                    podemos recopilar, con su permiso previo:
                </p>
                <br />
                <p>
                    Imágenes y otra información de la biblioteca de fotos y la cámara de su dispositivo.
                </p>
                <br />
                <p>
                    Usamos esta información para proporcionar características de Nuestro Servicio, para mejorarla y
                    personalizarlo. La información se puede cargar en los servidores de la Compañía y / o
                    en el servidor de un Proveedor de servicios o simplemente se puede almacenar en Su dispositivo.
                </p>
                <br />
                <p>
                    Puede habilitar o deshabilitar el acceso a esta información en cualquier momento, a través de la
                    configuración de su dispositivo.
                </p>


                <br />
                <h4>Uso de sus datos personales</h4>
                <br />
                <p>
                    La Compañía puede utilizar los Datos Personales para los siguientes propósitos:
                </p>
                <br />
                <ul style="list-style-type:circle">
                    <li>
                        Para proporcionar y mantener nuestro Servicio, incluso para controlar el uso de nuestro
                        Servicio.
                    </li>
                    <li>
                        Para administrar Su Cuenta: para administrar Su registro como usuario del Servicio. Los Datos
                        Personales
                        que proporcione pueden darle acceso a diferentes funcionalidades del Servicio que están
                        disponibles para
                        Usted como usuario registrado.
                    </li>
                    <li>
                        Para la ejecución de un contrato: el desarrollo, cumplimiento y ejecución del contrato de compra
                        de los productos, artículos o servicios que haya comprado o de cualquier otro contrato con
                        Nosotros a través del Servicio.
                    </li>
                    <li>
                        Para contactarlo: Para contactarlo por correo electrónico, llamadas telefónicas, SMS u otras
                        formas equivalentes de comunicación electrónica, como las notificaciones push de una aplicación
                        móvil sobre actualizaciones o comunicaciones informativas relacionadas con las funcionalidades,
                        productos o servicios contratados, incluidas las actualizaciones de seguridad, cuando sea
                        necesario o razonable para su implementación.
                    </li>
                    <li>
                        Para proporcionarle noticias, ofertas especiales e información general sobre otros bienes,
                        servicios y eventos que ofrecemos y que son similares a los que ya ha comprado o sobre los que
                        ha consultado, a menos que haya optado por no recibir dicha información.
                    </li>
                    <li>
                        Para gestionar sus solicitudes: Para atender y gestionar sus solicitudes para nosotros.
                    </li>
                    <li>
                        Para transferencias comerciales: podemos usar su información para evaluar o llevar a cabo una
                        fusión, desinversión, reestructuración, reorganización, disolución u otra venta o transferencia
                        de algunos o todos nuestros activos, ya sea como una empresa en funcionamiento o como parte de
                        una quiebra, liquidación, o procedimiento similar, en el que los Datos personales que tenemos
                        sobre los usuarios de nuestro Servicio se encuentran entre los activos transferidos.
                    </li>
                    <li>
                        Para otros fines: podemos utilizar su información para otros fines, como análisis de datos,
                        identificación de tendencias de uso, determinación de la eficacia de nuestras campañas
                        promocionales y para evaluar y mejorar nuestro Servicio, productos, servicios, marketing y su
                        experiencia.
                    </li>
                </ul>
                <p>
                    Podemos compartir su información personal en las siguientes situaciones:
                </p>
                <br>
                <ul style="list-style-type:circle">
                    <li>Con proveedores de servicios: podemos compartir su información personal con proveedores de
                        servicios para monitorear y analizar el uso de nuestro servicio, para comunicarnos con usted.
                    </li>
                    <li>
                        Para transferencias comerciales: podemos compartir o transferir Su información personal en
                        relación con, o durante las negociaciones de, cualquier fusión, venta de activos de la Compañía,
                        financiamiento o adquisición de la totalidad o una parte de Nuestro negocio a otra compañía.
                    </li>
                    <li>
                        Con afiliados: podemos compartir su información con nuestros afiliados, en cuyo caso les
                        exigiremos que cumplan con esta Política de privacidad. Los afiliados incluyen nuestra empresa
                        matriz y cualquier otra subsidiaria, socios de empresas conjuntas u otras empresas que
                        controlemos o que estén bajo control común con nosotros.
                    </li>
                    <li>
                        Con socios comerciales: podemos compartir su información con nuestros socios comerciales para
                        ofrecerle ciertos productos, servicios o promociones.
                    </li>
                    <li>
                        Con otros usuarios: cuando comparte información personal o interactúa de otra manera en las
                        áreas públicas con otros usuarios, dicha información puede ser vista por todos los usuarios y
                        puede distribuirse públicamente en el exterior. Si interactúa con otros usuarios o se registra a
                        través de un servicio de redes sociales de terceros, sus contactos en el servicio de redes
                        sociales de terceros pueden ver su nombre, perfil, imágenes y descripción de su actividad. Del
                        mismo modo, otros usuarios podrán ver descripciones de su actividad, comunicarse con usted y ver
                        su perfil.
                    </li>
                    <li>
                        Con su consentimiento: podemos divulgar su información personal para cualquier otro propósito
                        con su consentimiento.
                    </li>
                </ul>
                <br />
                <h2>Conservación de sus datos personales</h2>
                <br />
                <p>
                    La Compañía retendrá sus datos personales solo durante el tiempo que sea necesario para los fines
                    establecidos en esta Política de privacidad. Retendremos y usaremos sus datos personales en la
                    medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a
                    retener sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros
                    acuerdos y políticas legales.
                </p>
                <br />
                <p>
                    La Compañía también retendrá los Datos de uso con fines de análisis interno. Los datos de uso
                    generalmente se conservan durante un período de tiempo más corto, excepto cuando estos datos se
                    utilizan para fortalecer la seguridad o mejorar la funcionalidad de nuestro servicio, o estamos
                    legalmente obligados a conservar estos datos durante períodos de tiempo más largos.
                </p>
                <br />
                <h2>Transferencia de sus datos personales</h2>
                <br />
                <p>
                    Su información, incluidos los Datos personales, se procesa en las oficinas operativas de la Compañía
                    y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. Significa
                    que esta información puede transferirse y mantenerse en computadoras ubicadas fuera de su estado,
                    provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden
                    diferir de las de su jurisdicción.
                </p>
                <br />
                <p>
                    Su consentimiento a esta Política de privacidad seguido de Su envío de dicha información representa
                    Su acuerdo con esa transferencia.
                </p>
                <br />
                <p>
                    La Compañía tomará todas las medidas razonablemente necesarias para garantizar que Sus datos sean
                    tratados de forma segura y de acuerdo con esta Política de Privacidad y no se realizará ninguna
                    transferencia de Sus Datos Personales a una organización.
                </p>
                <br />
                <h2>Divulgación de sus datos personales</h2>
                <br />
                <h3>Transacciones de negocios</h3>
                <br />
                <p>
                    Si la Compañía está involucrada en una fusión, adquisición o venta de activos, sus datos personales
                    pueden ser transferidos. Le avisaremos antes de que sus datos personales se transfieran y estén
                    sujetos a una política de privacidad diferente.
                </p>
                <br />
                <h3>Cumplimiento de la ley</h3>
                <br />
                <p>
                    En determinadas circunstancias, es posible que la Compañía deba divulgar sus datos personales si así
                    lo exige la ley o en respuesta a solicitudes válidas de autoridades públicas (por ejemplo, un
                    tribunal o una agencia gubernamental).
                </p>
                <br />
                <h3>Otros requisitos legales</h3>
                <br />
                <p>
                    La Compañía puede divulgar sus datos personales si cree de buena fe que dicha acción es necesaria
                    para:
                </p>
                <br>
                <ul>
                    <li>Cumplir con una obligación legal</li>
                    <li>Proteger y defender los derechos o propiedad de la Compañía.</li>
                    <li>Prevenir o investigar posibles irregularidades en relación con el Servicio.</li>
                    <li>Proteger la seguridad personal de los Usuarios del Servicio o del público</li>
                </ul>
                <br />
                <h3>Seguridad de sus datos personales</h3>
                <br />
                <p>
                    La seguridad de sus datos personales es importante para nosotros, pero recuerde que ningún método de
                    transmisión a través de Internet o método de almacenamiento electrónico es 100% seguro. Si bien nos
                    esforzamos por utilizar medios comercialmente aceptables para proteger sus datos personales, no
                    podemos garantizar su seguridad absoluta.
                </p>
                <br />
                <h2>Privacidad de los niños</h2>
                <br />
                <p>
                    Nuestro Servicio no se dirige a ninguna persona menor de 14 años. No recopilamos a sabiendas
                    información de identificación personal de ninguna persona menor de 14 años. Si usted es un padre o
                    tutor y sabe que su hijo nos ha proporcionado datos personales, por favor Contáctenos. Si nos damos
                    cuenta de que hemos recopilado Datos personales de cualquier persona menor de 13 años sin
                    verificación del consentimiento de los padres, tomamos medidas para eliminar esa información de
                    Nuestros servidores.
                </p>
                <br />
                <p>
                    Si necesitamos depender del consentimiento como base legal para procesar su información y su país
                    requiere el consentimiento de uno de los padres, es posible que necesitemos el consentimiento de sus
                    padres antes de recopilar y utilizar esa información.

                </p>
                <br />
                <h3>Enlaces a otros sitios web</h3>
                <br />
                <p>
                    Nuestro Servicio puede contener enlaces a otros sitios web que no son operados por Nosotros. Si hace
                    clic en el enlace de un tercero, será dirigido al sitio de ese tercero. Le recomendamos
                    encarecidamente que revise la Política de privacidad de cada sitio que visite.
                </p>
                <br />
                <p>
                    No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad
                    o las prácticas de sitios o servicios de terceros.
                </p>
                <br />
                <h3>Cambios a esta política de privacidad</h3>
                <br />
                <p>
                    Es posible que actualicemos nuestra Política de privacidad de vez en cuando. Le notificaremos de
                    cualquier cambio publicando la nueva Política de privacidad en esta página.
                </p>
                <br />
                <p>
                    Le informaremos por correo electrónico y / o un aviso destacado en Nuestro Servicio, antes de que el
                    cambio entre en vigor y actualizaremos la fecha de "Última actualización" en la parte superior de
                    esta Política de privacidad.
                </p>
                <br />
                <p>
                    Se le recomienda que revise esta Política de privacidad periódicamente para ver si hay cambios. Los
                    cambios a esta Política de privacidad entran en vigencia cuando se publican en esta página.
                </p>
                <br />
                <h3>Contacto</h3>
                <br />
                <p>
                    Si tiene alguna pregunta sobre esta Política de privacidad, puede contactarnos:
                </p>
                <br>
                <p>
                    Por correo electrónico: <a href="mailto:contacto@fabioarias.co">contacto@fabioarias.co</a>
                </p>
                <br>
                <p>
                    Al visitar esta página en nuestro sitio web: <a
                        href="https://www.fabioarias.co">https://www.fabioarias.co</a>
                </p>
            </div>

        </div>
    </div>
</div>