<div class="limiter">
  <div class="container-login100" style="background-image: url('assets/img/bg-01.jpg');">
    <div class="wrap-login100">
      <div class="login100-form validate-form">
        <span class="login100-form-logo mb-3">
          <img src="assets/img/logo.png" width="110px">
        </span>

        <span class="login100-form-title p-b-34 p-t-27">
          {{ resetPassword ? 'Recuperar contraseña' : 'Iniciar Sesión' }}
        </span>

        <div *ngIf="!resetPassword">
          <div class="wrap-input100 validate-input mt-2">
            <input class="input100" type="text" placeholder="Email" id="email" [(ngModel)]="login.email"
              autocomplete="off">
            <span class="focus-input100" data-placeholder="&#xf15a;"></span>
          </div>
  
          <div class="wrap-input100 validate-input">
            <input class="input100" type="password" placeholder="Contraseña" id="password" [(ngModel)]="login.password"
              autocomplete="off">
            <span class="focus-input100" data-placeholder="&#xf191;"></span>
          </div>

          <div class="container-login100-form-btn">
            <button class="login100-form-btn" (click)="loginAdmin()">
              Ingresar
            </button>
          </div>
        </div>

        <div *ngIf="resetPassword">

          <div class="wrap-input100 validate-input mt-5">
            <input class="input100" type="text" placeholder="Email" id="resetEmail" [(ngModel)]="email"
              autocomplete="off">
            <span class="focus-input100" data-placeholder="&#xf15a;"></span>
          </div>

          <div class="container-login100-form-btn">
            <button class="login100-form-btn" (click)="passwordReset()">
              Recuperar
            </button>
          </div>

        </div>

        <div class="text-center pt-3">
          <a class="txt1" (click)="resetPassword = !resetPassword" style="color: white; cursor: pointer;">
            {{ !resetPassword ? '¿No puedes iniciar sesión?' : 'Iniciar Sesión' }}
          </a>
          <span class="txt1 mx-2"> | </span>
          <a class="txt1" href="/register">
            Registrarme
          </a>
        </div>
      </div>
    </div>
  </div>
</div>