<div class="limiter">
  <div class="container-login100" style="background-image: url('assets/img/bg-01.jpg');">
    <div class="wrap-singup100">
      <div class="login100-form validate-form">
        <span class="login100-form-logo">
          <i class="zmdi zmdi-account"></i>
        </span>

        <span class="login100-form-title pt-3 pb-3">
          Visita
        </span>

        <div class="row">
          <div class="col-md-12 text-center">
            <span style="color: white;">Ingrese el código para tener información acerca de la visita</span>
          </div>
          <div class="col-md-12 pt-2">
            <div class="wrap-input100">
              <input class="input100" type="text" placeholder="Código" id="code" autocomplete="off" [(ngModel)]="code">
              <span class="focus-input100" data-placeholder="&#xf263;"></span>
            </div>
          </div>
        </div>
        <div class="container-login100-form-btn">
          <button class="login100-form-btn" (click)="sendCode()">
            Enviar código
          </button>
        </div>
      </div>
    </div>
  </div>
</div>