<div class="limiter">
  <div class="container-login100" style="background-image: url('assets/img/bg-01.jpg');">
    <div class="wrap-singup100">
      <div class="login100-form validate-form" >
        <span class="login100-form-logo">
          <i class="zmdi zmdi-account"></i>
        </span>

        <span class="login100-form-title pt-3 pb-3">
          Visita
        </span>
        <div class="row" >
          <div class="col-md-6">
            <div class="wrap-input100">
              <select class="input100" style="border: 0px !important;" [(ngModel)]="sector" id="sector" [disabled] = "!validated"
                (ngModelChange)="listHome()">
                <option value="" class="option">Seleccionar sector</option>
                <option *ngFor="let sector of listaSectores" [value]="sector.name" class="option">{{ sector.name | uppercase  }}
                </option>
              </select>
              <span class="focus-input100" data-placeholder="&#xf133;"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="wrap-input100">
              <select class="input100" style="border: 0px !important;" [(ngModel)]="home" id="home">
                <option value="" class="option">Seleccionar casa</option>
                <option *ngFor="let home of listaCasaSector" [value]="home.name" class="option">{{ home.name | uppercase  }}</option>
              </select>
              <span class="focus-input100" data-placeholder="&#xf175;"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="wrap-input100">
              <input class="input100" type="text" placeholder="Nombre visitante" id="name" autocomplete="off"
                [(ngModel)]="name">
              <span class="focus-input100" data-placeholder="&#xf207;"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="wrap-input100">
              <input class="input100" type="text" placeholder="Celular visitante" id="phone" autocomplete="off"
                [(ngModel)]="phone">
              <span class="focus-input100" data-placeholder="&#xf2be;"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="wrap-input100">
              <input class="input100" type="email" placeholder="Email visitante" id="email" autocomplete="off"
                [(ngModel)]="email">
              <span class="focus-input100" data-placeholder="&#xf15a;"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="wrap-input100">
              <select class="input100" style="border: 0px !important;" id="reason" [(ngModel)]="reason">
                <option value="" class="option">Seleccionar Motivo</option>
                <option value="VISITA" class="option">VISITA</option>
                <option value="DOMICILIO" class="option">DOMICILIO</option>
                <option value="CORRESPONDENCIA" class="option">CORRESPONDENCIA</option>
              </select>
              <span class="focus-input100" data-placeholder="&#xf20b;"></span>
            </div>
          </div>
          <div class="col-md-12">
            <p class="politica">
              * Al dar clic en el boton <b>Completar Visita</b> esta aceptando nuestra <a href="/politica" target="_blank">Politica de tratamiento de datos y privacidad</a>
            </p>
            <br/>
          </div>
        </div>

        <div class="row justify-content-center pb-3" *ngIf="!sendVideo">
          <div class="col-md-12 text-center pb-2">
            <span style="color: white; font-family: Poppins-Regular; font-size: 16px;"> <i
                class="zmdi zmdi-videocam"></i> Video {{ reason }}</span>
          </div>
          <video width="auto" height="220px" id="video-visit" class="video-js" playsinline></video>
        </div>

        <div class="container-login100-form-btn">
          <button class="login100-form-btn" (click)="createVisit()">
            Completar Visita
          </button>
        </div>

      </div>
    </div>
  </div>
</div>