import { Component, OnDestroy, OnInit, ElementRef } from '@angular/core';
import videojs from 'video.js';
import * as RecordRTC from 'recordrtc';
import * as Record from 'videojs-record/dist/videojs.record.js';
import { ApiService } from '../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { Sector } from '../model/sector';
import { Home } from '../model/home';
import { UploadService } from '../services/upload.service';
import { loadingFireToast } from 'src/assets/js/toast.js';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.scss'],
  providers: [UploadService]
})
export class VisitComponent implements OnInit, OnDestroy {

  tokenTemp: string;
  name: string;
  phone: number;
  email: string;
  reason: string = '';
  sendVideo: boolean = false;
  urlVideo: string;
  validar: boolean = false;
  listaSectores: Array<Sector> = [];
  listaCasaSector: Array<Home> = [];
  sector: string = '';
  home: string = '';
  idBuilding: number;
  player: any;
  plugin: any;
  config: any;
  validated = false;

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private uploadService: UploadService
  ) {

    this.player = false;
    this.plugin = Record;
    this.config = {
      controls: true,
      autoplay: false,
      fluid: false,
      loop: false,
      bigPlayButton: true,
      controlBar: {
        volumePanel: true
      },
      plugins: {
        record: {
          audio: true,
          video: true,
          debug: true,
          maxLength: 10
        }
      }
    };
  }

  ngOnInit(): void {

    this.generateToken();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.idBuilding = params.idBuilding;

    });
  }


  validateBuilding() {
    this.apiService.get(`auth/validate/${this.idBuilding}`).subscribe(
      (res) => {
        console.log(res);
        this.validated = true;
      }
    );
  }

  generateToken() {
    this.apiService.post('generate-token', { info: this.idBuilding }).subscribe(
      (res) => {
        // console.log('res', res);
        this.tokenTemp = res.data.token;
        this.listarSectores();
      },
      (error) => {
        console.log('error generando el token', error);
      }
    );
  }

  listarSectores() {
    this.apiService.get(`residente/sector-name/${this.idBuilding}/${this.tokenTemp}`).subscribe(
      (res) => {
        console.log('res sector', res);
        this.listaSectores = res.data;
      },
      (error) => {
        console.log('error consultando los sectores', error);
      }
    );
  }

  listHome() {
    const sector = this.listaSectores.find(item => item.name === this.sector);
    this.apiService.get(`residente/home-name/${sector.id}/${this.tokenTemp}`).subscribe(
      (res) => {
        // console.log('res home', res);
        this.listaCasaSector = res.data;
      },
      (error) => {
        console.log('error consultando los home name', error);
      }
    );
  }

  ngAfterViewInit() {

    const el = 'video-visit';
    const element = document.getElementById(el);
    this.player = videojs(element, this.config, () => {
      const msg = 'Using videojs ' + videojs.VERSION +
        ' with videojs-record ' + videojs.getPluginVersion('record') +
        ' and recordrtc ' + RecordRTC.version;
      videojs.log(msg);
    });

    this.player.on('deviceReady', () => {
      // console.log('device is ready');
    });

    this.player.on('startRecord', () => {
      // console.log('started recording!');
    });

    this.player.on('finishRecord', async () => {
      // console.log(this.player);
      // console.log(typeof this.player.recordedData);
      // console.log('finished recording: ', this.player.recordedData);
      const loader = loadingFireToast('Subiendo video de la visita, por favor espere...');
      if (this.validated) {
        const upload = await this.uploadService.uploadFile(this.player.recordedData, this.idBuilding);
        // console.log('upload', upload);
        loader.close();
        if (upload) {
          this.urlVideo = upload.Location;
          this.sendVideo = true;
          Swal
            .fire({
              icon: 'success',
              title: 'Exito',
              text: 'El video se ha cargado correctamente.',
              timer: 2000,
            });
        } else {
          Swal.fire('Error', 'Ocurrió un error subiendo el vídeo', 'warning');
        }
      }else{
        Swal.fire('Error', 'El servicio para este Edificio no se encuentra disponible', 'error');
      }


    });

    this.player.on('error', (element, error) => {
      console.warn(error);
    });

    this.player.on('deviceError', () => {
      console.error('device error:', this.player.deviceErrorCode);
    });
    this.validateBuilding();
  }

  createVisit() {
    if (!this.validateVisit()) {
      return;
    }
    const params = {
      idBuilding: this.idBuilding,
      sector: this.sector,
      home: this.home,
      name: this.name,
      phone: this.phone,
      email: this.email,
      motivo: this.reason,
      video: this.urlVideo
    }
    console.log('params', params);
    const loader = loadingFireToast('Generando visita, por favor espere...');
    this.apiService.post('visita/register-visit', params).subscribe(
      (res) => {
        loader.close();
        console.log('res', res);
        Swal
          .fire({
            icon: 'success',
            title: 'Exito',
            text: 'La visita se realizó correctamente, recibirá la confirmación via mensaje de texto.',
            timer: 5000,
          }).then(() => {
            location.reload();
          });
      },
      (error) => {
        loader.close();
        console.log('error generando la visita', error);
        Swal.fire('Error', 'Ocurrio un error generando la visita', 'error');
      }
    );
  }

  validateVisit() {
    if (!this.validar) {
      this.validar = true;
      this.validate(this.validar, this.sector, 'El sector al que se dirige es requerido.', 'sector');
      this.validate(this.validar, this.home, 'La casa a la cual se dirige es requerida.', 'home');
      this.validate(this.validar, this.name, 'El nombre del visitante es requerido.', 'name');
      this.validate(this.validar, this.phone, 'El celular del visitante es requerido.', 'phone');
      this.validate(this.validar, this.email, 'El email del visitante es requerido.', 'email');
      this.validate(this.validar, /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.email), 'El email del visitante no es válido.', 'email');
      this.validate(this.validar, this.reason, 'Debe seleccionar un motivo', 'reason');
      this.validate(this.validar, this.sendVideo, 'Debe enviar un vídeo', 'video-visit');
    }
    return this.validar;
  }

  validate(validate, condition, msg, id) {
    if (validate) {
      if (!condition) {
        this.validar = false;
        this.fireToast(msg);
        this.showField(id);
        return false;
      }
    }
  }

  fireToast(msg) {
    Swal.fire('Confirmación', msg, 'info');
  }

  showField(id) {
    const element = document.getElementById(id);
    const properties = element.getBoundingClientRect();
    window.scrollTo({
      top: properties.y,
      behavior: 'smooth'
    });
    document.getElementById(id).focus();
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
      this.player = false;
    }
  }
}
