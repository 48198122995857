import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  admin : any;
  percentUseStorage : number;
  percentUseResidents : number;

  constructor(public apiService: ApiService) {
    this.apiService.get('auth/get-info').subscribe(
      (res) => {
        // console.log('admin', res);
        this.admin = res.data;
        console.log(this.admin);
        this.percentUseStorage = ((this.admin.storage / (1024 * 1024 * 1024)) / this.admin.plan_max_storage)* 100 ;
        this.percentUseResidents = (this.admin.residents / this.admin.plan_max_residents) * 100;
      },
      (error) => {
        console.log('error consultando la información del admin', error);
      }
    );
   }


  

  ngOnInit(): void {
  }

}
