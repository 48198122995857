import { NgModule } from '@angular/core';
import { DashboardLayoutComponent } from '../layout/dashboard-layout/dashboard-layout.component';
import { DashboardComponent } from './dashboard.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateResidentsComponent } from './create-residents/create-residents.component';
import { AuthGuard } from '../auth.guard';
import { ManageResidentsComponent } from './manage-residents/manage-residents.component';
import { VisitReportComponent } from './visit-report/visit-report.component';

const routes: Routes = [
  {
    path: 'app',
    canActivate: [AuthGuard],
    component: DashboardLayoutComponent,
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'create-residents',
        component: CreateResidentsComponent
      },
      {
        path: 'manage-residents',
        component: ManageResidentsComponent
      },
      {
        path: 'visit-report',
        component: VisitReportComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, FormsModule, ReactiveFormsModule]
})
export class DashboardRoutingModule { }
