import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-info-visit',
  templateUrl: './info-visit.component.html',
  styleUrls: ['./info-visit.component.scss']
})
export class InfoVisitComponent implements OnInit {

  code: string;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
  }

  sendCode() {
    if (!this.code) {
      Swal.fire('Confirmación', 'El código es requerido.', 'info');
      return;
    }
    console.log('enviar código');
    const params = {
      otp: this.code
    }
    this.apiService.post('visita/get-visit', params).subscribe(
      (res) => {
        console.log('res', res);
        const data = res.data;
        const msg = `La persona: ${data.visitante} ha sido aceptada por el residente: ${data.residente} para dirigirse al sector: ${data.sector_name}, apto: ${data.home_name} con motivo: ${data.motivo}.`;
        Swal.fire('Confirmación', msg, 'info');
        this.code = '';
      },
      (error) => {
        console.log('error consultando la información de la visita', error);
        Swal.fire('Confirmación', error, 'info');
      }
    );
  }

}
