import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Admin } from 'src/app/model/admin';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  admin: Admin;

  constructor(private authService: AuthService, private router: Router, private apiService: ApiService) { }

  ngOnInit(): void {
    this.apiService.get('auth/get-info').subscribe(
      (res) => {
        // console.log('admin', res);
        this.admin = res.data;
      },
      (error) => {
        console.log('error consultando la información del admin', error);
      }
    );
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

}
