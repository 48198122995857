<div class="row justify-content-center">
  <div class="col-lg-10">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div>
              <h4 class="text-center">Código visitante</h4>
            </div>
            <div class="d-flex justify-content-center">
              <canvas id="canvas_visitante"></canvas> <img src="" alt="" id="image_visitante" />
            </div>
            <div class="d-flex justify-content-center">
              <button (click)='downloadQr(1)' class="btn btn-success">
                <i class="zmdi zmdi-download"></i>
                Descargar</button>
            </div>
          </div>
          <div class="col">
            <div>
              <h4 class="text-center">Código Porteria</h4>
            </div>
            <div class="d-flex justify-content-center">
              <canvas id="canvas_porteria"></canvas> <img src="" alt="" id="image_porteria" />
            </div>
            <div class="d-flex justify-content-center">
              <button (click)='downloadQr(2)' class="btn btn-success">
                <i class="zmdi zmdi-download"></i>
                Descargar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>