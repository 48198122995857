import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Admin } from 'src/app/model/admin';
import { Home } from 'src/app/model/home';
import { Sector } from 'src/app/model/sector';
import { Visit } from 'src/app/model/visit';
import { ApiService } from 'src/app/services/api.service';
import { VisitService } from 'src/app/services/visit.service';

@Component({
  selector: 'app-visit-report',
  templateUrl: './visit-report.component.html',
  styleUrls: ['./visit-report.component.scss'],
  providers: [VisitService, DecimalPipe]
})
export class VisitReportComponent implements OnInit {

  admin: Admin;
  from: string;
  to: string;
  estado: number = 0;
  listaSectores: Array<Sector> = [];
  listaCasaSector: Array<Home> = [];
  sector: string = '';
  home: string = '';
  token: string;
  datosVisitas: Observable<Visit[]>;
  VisitFilter: Observable<Visit[]>;
  total$: Observable<number>;
  collapseInfo: boolean = false;
  visitaSeleccionada: Visit;
  closeResult: string = '';
  mime: string = '';

  constructor(
    private apiService: ApiService,
    public service: VisitService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {

    this.token = JSON.parse(localStorage.getItem('token'));

    this.apiService.get('auth/get-info').subscribe(
      (res) => {
        // console.log('admin', res);
        this.admin = res.data;
        this.listarSectores();
      },
      (error) => {
        console.log('error consultando la información del admin', error);
      }
    );
  }

  listarSectores() {
    this.apiService.get(`residente/sector-name/${this.admin.id}/${this.token}`).subscribe(
      (res) => {
        this.listaSectores = res.data;
      },
      (error) => {
        console.log('error consultando los sectores', error);
      }
    );
  }

  listHome() {
    const sector = this.listaSectores.find(item => item.name === this.sector);
    this.apiService.get(`residente/home-name/${sector.id}/${this.token}`).subscribe(
      (res) => {
        // console.log('res home', res);
        this.listaCasaSector = res.data;
      },
      (error) => {
        console.log('error consultando los home name', error);
      }
    );
  }

  searchVisit() {
    this.loadVisit();
    this.VisitFilter = this.service.getVisitas$;
    this.datosVisitas = this.service.allData;
    this.total$ = this.service.total$;
  }

  loadVisit() {
    const params = {
      from: this.from ? this.from + ' 00:00:00' : '',
      to: this.to ? this.to + ' 23:59:59' : '',
      sector: this.sector,
      home: this.home,
      estado: this.estado
    }
    this.service.searchVisit(params);
  }

  goBack() {
    this.service.searchTerm = '';
    this.service.backFilter();
  }

  detailVisit(visita, visitDetail) {
    this.visitaSeleccionada = visita;
    this.getMimetype();
    this.modalService.open(visitDetail, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getMimetype() {
    if (this.visitaSeleccionada.video) {
      if (this.visitaSeleccionada.video.endsWith(".mkv") || this.visitaSeleccionada.video.endsWith(".mp4")) {
        this.mime = 'video/mp4';
      } else {
        this.mime = 'video/webm';
      }
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
