import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AcceptVisitComponent } from './accept-visit/accept-visit.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { InfoVisitComponent } from './info-visit/info-visit.component';
import { PoliticaComponent } from './politica/politica.component';
import { UpdateContactInformationComponent } from './update-contact-information/update-contact-information.component';
import { VisitComponent } from './visit/visit.component';

const routes: Routes = [
  {
    path: 'update-contact-information',
    component: UpdateContactInformationComponent
  },
  {
    path: 'visit',
    component: VisitComponent
  },
  {
    path: 'response-visit',
    component: AcceptVisitComponent
  },
  {
    path: 'confirm-visit',
    component: InfoVisitComponent
  },
  {
    path: 'politica',
    component: PoliticaComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
