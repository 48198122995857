import { Component, OnInit } from '@angular/core';
// import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { fireToast, loadingFireToast } from 'src/assets/js/toast.js';
// import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-residents',
  templateUrl: './create-residents.component.html',
  styleUrls: ['./create-residents.component.scss']
})
export class CreateResidentsComponent implements OnInit {

  file: any;

  constructor(
    private apiService: ApiService
    // private router: Router
  ) { }

  ngOnInit(): void {
  }

  cargarResidentes(event) {
    const labelFile = document.getElementById('label-file');
    labelFile.innerHTML = 'Seleccionar archivo';
    this.file = event.target.files[0];
    console.log('file', this.file);
    labelFile.innerHTML = this.file.name.toLocaleLowerCase();
    const loading = loadingFireToast('Cargando archivo, por favor espere...');
    let formData = new FormData();
    formData.append('file', this.file);
    this.apiService.post('residente/upload', formData).subscribe(
      (res) => {
        console.log('res', res);
        loading.close();
        labelFile.innerHTML = 'Seleccionar archivo';
        fireToast('success', 'Residentes creados correctamente.');
      },
      (error) => {
        loading.close();
        console.log('error subiendo el archivo', error);
      }
    );
  }

}
