import { Component, OnInit } from '@angular/core';
import { Admin } from '../model/admin';
import { ApiService } from '../services/api.service';
import QrCodeWithLogo from "qrcode-with-logos";
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  admin: Admin;
  urlVisitante: string = 'https://app.veciino.co/visit?idBuilding=';
  urlPortero: string = 'https://app.veciino.co/confirm-visit?idBuilding=';
  qrcodeVisitante: QrCodeWithLogo;
  qrcodePorteria: QrCodeWithLogo;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.apiService.get('auth/get-info').subscribe(
      (res) => {
        // console.log('admin', res);
        this.admin = res.data;
        this.urlVisitante += this.admin.id;
        this.urlPortero += this.admin.id;
        this.qrcodeVisitante = new QrCodeWithLogo({
          canvas: <HTMLCanvasElement> document.getElementById("canvas_visitante"),
          content: this.urlVisitante,
          width: 380,
          logo: {
            src: 'assets/img/logo.png',
            borderRadius: 50,
          }
        });
        this.qrcodeVisitante.toCanvas();
        this.qrcodePorteria = new QrCodeWithLogo({
          canvas: <HTMLCanvasElement> document.getElementById("canvas_porteria"),
          content: this.urlPortero,
          width: 380,
          logo: {
            src: 'assets/img/logo.png',
            borderRadius: 50,
          },
        });
        this.qrcodePorteria.toCanvas();
      },
      (error) => {
        console.log('error consultando la información del admin', error);
      }
    );
  }


  downloadQr(type: number){
    switch(type){
      case 1:
        this.qrcodeVisitante.downloadImage("QRCODE-VECIINO-VISITANTE");
      break;
      case 2:
        this.qrcodeVisitante.downloadImage("QRCODE-VECIINO-PORTERIA");
      break;
    }
  }

}
