<div class="main-sidebar">
  <aside id="sidebar-wrapper">
    <div class="sidebar-brand">
      <a href="/app"><img src="assets/img/logo.png" width="80px"></a>
    </div>
    <div class="sidebar-brand sidebar-brand-sm">
      <a href="/app"> <i class="fa fa-home" style="font-size: 20px;" aria-hidden="true"></i> </a>
    </div>
    <ul class="sidebar-menu">
      <li class="menu-header">Dashboard</li>
      <li style="cursor: pointer;">
        <a class="nav-link " routerLink="/app"><i class="fas fa-home"></i><span>Dashboard</span></a>
      </li>
      <li style="cursor: pointer;">
        <a class="nav-link " routerLink="/app/create-residents"><i class="fas fa-download"></i><span>Crear
            residentes</span></a>
      </li>
      <li style="cursor: pointer;">
        <a class="nav-link " routerLink="/app/manage-residents"><i class="fas fa-user"></i><span>Gestionar
            residentes</span></a>
      </li>
      <li style="cursor: pointer;">
        <a class="nav-link " routerLink="/app/visit-report"><i class="fas fa-clipboard-list"></i><span>Reporte de
            visitas</span></a>
      </li>
      
    </ul>
    <div class="storage">
      <p>Máximo Almacenamiento: {{admin.plan_max_storage}} GB</p>
      <ngb-progressbar  [value]="percentUseStorage" [showValue]="true">
      </ngb-progressbar>
    </div>
    <div class="storage">
      <p>Máximo Residentes: {{admin.plan_max_residents}} </p>
      <ngb-progressbar  [value]="percentUseResidents" [showValue]="true">
      </ngb-progressbar>
    </div>
    <div class="storage">
      <p>Valido hasta: {{ admin.validUntil | date: 'yyyy-MM-dd'}}</p>
    </div>
    
  </aside>
</div>