export class Admin {
  id?: number;
  name!: string;
	address!: string;
	department!: string;
	city!: string;
	adminPhone!: string;
	password!: string;
	adminName!: string;
	adminLastName!: string;
	adminEmail!: string;
	nombre!: string;
}
