import { Component, OnInit } from '@angular/core';
import { Resident } from 'src/app/model/resident';
import { ApiService } from 'src/app/services/api.service';
import { fireToast, loadingFireToast } from 'src/assets/js/toast.js';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manage-residents',
  templateUrl: './manage-residents.component.html',
  styleUrls: ['./manage-residents.component.scss']
})
export class ManageResidentsComponent implements OnInit {

  listResidents: Array<Resident> = [];

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.consultarResidentes();
  }

  consultarResidentes() {
    const loading = loadingFireToast('Cargando residentes, por favor espere...');
    this.apiService.get(`residente/get-resident`).subscribe(
      (res) => {
        loading.close();
        console.log('residentes', res);
        this.listResidents = res.data;
      },
      (error) => {
        loading.close();
        console.log('error consultando los residentes del building', error);
      }
    );
  }

  deleteResidents() {
    Swal.fire({
      title: 'Advertencia',
      text: '¿Está seguro(a) de eliminar todos sus residentes?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.eliminarResidentes();
      }
    });
  }

  eliminarResidentes() {
    const loading = loadingFireToast('Eliminando residentes, por favor espere...');
    this.apiService.get(`residente/delete-resident`).subscribe(
      (res) => {
        loading.close();
        console.log('res', res);
        Swal
          .fire({
            icon: 'success',
            title: 'Exito',
            text: 'Residentes eliminados correctamente.',
            timer: 2000,
          }).then(() => {
            this.consultarResidentes();
          });
      },
      (error) => {
        loading.close();
        console.log('error consultando los residentes del building', error);
      }
    );
  }

}
