<div class="limiter">
  <div class="container-login100" style="background-image: url('assets/img/bg-01.jpg');">
    <div class="wrap-singup100">
      <div class="login100-form validate-form">
        <span class="login100-form-logo">
          <i class="zmdi zmdi-home"></i>
        </span>

        <span class="login100-form-title pt-3 pb-3">
          Registro
        </span>

        <div class="row">
          <div class="col-md-6">
            <div class="wrap-input100">
              <input class="input100" type="text" placeholder="Edificio" [(ngModel)]="admin.name" id="name" autocomplete="off">
              <span class="focus-input100" data-placeholder="&#xf133;"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="wrap-input100">
              <input class="input100" type="text" placeholder="Teléfono Administrador" [(ngModel)]="admin.adminPhone"
                id="adminPhone" autocomplete="off">
              <span class="focus-input100" data-placeholder="&#xf2be;"></span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="wrap-input100">
              <input class="input100" type="text" placeholder="Dirección" [(ngModel)]="admin.address" id="address" autocomplete="off">
              <span class="focus-input100" data-placeholder="&#xf1ab;"></span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="wrap-input100">
              <select class="input100" style="border: 0px !important;" [(ngModel)]="admin.department" id="department"
                (change)="findCities()" autocomplete="off">
                <option value="" class="option">Seleccionar</option>
                <option [value]="item.departamento" class="option" *ngFor="let item of departamentos">
                  {{item.departamento}}</option>
              </select>
              <span class="focus-input100" data-placeholder="&#xf1ab;"></span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="wrap-input100">
              <select class="input100" style="border: 0px !important;" [(ngModel)]="admin.city" id="city" autocomplete="off">
                <option value="" class="option">Seleccionar</option>
                <option [value]="item.nombre" class="option" *ngFor="let item of ciudades">{{item.nombre}}
                </option>
              </select>
              <span class="focus-input100" data-placeholder="&#xf1ab;"></span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="wrap-input100">
              <input class="input100" type="text" placeholder="Nombre Administrador" [(ngModel)]="admin.adminName"
                id="adminName" autocomplete="off">
              <span class="focus-input100" data-placeholder="&#xf207;"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="wrap-input100">
              <input class="input100" type="text" placeholder="Apellido Administrador" [(ngModel)]="admin.adminLastName"
                id="adminLastName" autocomplete="off">
              <span class="focus-input100" data-placeholder="&#xf207;"></span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="wrap-input100">
              <input class="input100" type="password" placeholder="Contraseña" [(ngModel)]="admin.password"
                id="password" autocomplete="off">
              <span class="focus-input100" data-placeholder="&#xf191;"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="wrap-input100">
              <input class="input100" type="text" placeholder="Correo Administrador" [(ngModel)]="admin.adminEmail"
                id="adminEmail" autocomplete="off">
              <span class="focus-input100" data-placeholder="&#xf15a;"></span>
            </div>
          </div>
          <div class="col-md-12">
            <p class="politica">
              * Al dar clic en el boton <b>Registrame</b> esta aceptando nuestra <a href="/politica" target="_blank">Politica de tratamiento de datos y privacidad</a>
            </p>
            <br/>
          </div>
        </div>

        <div class="container-login100-form-btn">
          <button class="login100-form-btn" (click)="registerAdmin()">
            Registrarme
          </button>
        </div>

        <div class="text-center pt-1">
          <a class="txt1" href="">
            Volver al login
          </a>
        </div>
      </div>
    </div>
  </div>
</div>