import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ApiService } from '../services/api.service';
import { loadingFireToast } from 'src/assets/js/toast.js';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-accept-visit',
  templateUrl: './accept-visit.component.html',
  styleUrls: ['./accept-visit.component.scss']
})
export class AcceptVisitComponent implements OnInit {

  id: number;
  sector: string;
  home: string;
  name: string;
  phone: string;
  email: string;
  reason: string = 'Motivo: ';
  video: string;
  acceptVisit: string = '';
  reasonDenay: string;
  mime: string = '';

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const data: any = jwt_decode(params.token);
      console.log('data', data);
      this.id = data.id;
      this.sector = data.sector;
      this.home = data.home;
      this.name = data.name;
      this.phone = data.phone;
      this.email = data.email;
      this.reason += data.motivo;
      this.video = data.video;

    });
  }

  getMimetype(){
    if(this.video.endsWith(".mkv") || this.video.endsWith(".mp4")){
      this.mime = 'video/mp4';
    }else{
      this.mime = 'video/webm';
    }
  }

  changeResponseVisit() {
    if (this.acceptVisit === 'SI') {
      this.reasonDenay = '';
    }
  }

  sendResponseVisit() {
    if (!this.acceptVisit) {
      Swal.fire('Confirmación', 'Debe responder si desea recibir la visita', 'info');
      return;
    }
    if (this.acceptVisit === 'NO' && !this.reasonDenay) {
      Swal.fire('Confirmación', 'Debe especificar un motivo', 'info');
      return;
    }
    const params = {
      id: this.id,
      respuesta: this.acceptVisit === 'SI' ? 1 : 0,
      motivoRechazo: this.reasonDenay,
      phone: this.phone,
      sector: this.sector,
      home: this.home
    }
    const loader = loadingFireToast('Enviando respuesta, por favor espere...');
    this.apiService.post('visita/accept-visit', params).subscribe(
      (res) => {
        loader.close();
        console.log('res', res);
        Swal
          .fire({
            icon: 'success',
            title: 'Exito',
            text: 'La respuesta a la persona se ha enviado correctamente.',
            timer: 5000,
          }).then(() => {
            this.router.navigate(['/']);
          });
      },
      (error) => {
        loader.close();
        console.log('error guardando respuesta a la visita', error);
        Swal.fire('Confirmación', error, 'info');
      }
    );
  }
}
