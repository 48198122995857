import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';
import { Resident } from '../model/resident';
import { loadingFireToast } from 'src/assets/js/toast.js';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-update-contact-information',
  templateUrl: './update-contact-information.component.html',
  styleUrls: ['./update-contact-information.component.scss']
})
export class UpdateContactInformationComponent implements OnInit {

  resident: Resident = {
    email: '',
    emailConfirmed: null,
    homeName: '',
    idBuilding: null,
    lastName: '',
    name: '',
    phone: '',
    sectorName: '',
    status: null,
    username: ''
  };
  validar: boolean = false;
  update: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const data: any = jwt_decode(params.update);
      this.resident.id = data.id;
      this.resident.idBuilding = data.id_building;
      this.resident.name = data.name;
      this.resident.lastName = data.last_name;
      this.resident.sectorName = data.sector_name;
      this.resident.homeName = data.home_name;
      this.resident.email = data.email;
      this.resident.status = 1;
      this.resident.emailConfirmed = 1;
    });
  }

  validateResident() {
    if (!this.validar) {
      this.validar = true;
      this.validate(this.validar, this.resident.sectorName, 'El sector es requerido.', 'sector');
      this.validate(this.validar, this.resident.homeName, 'El Nombre/Número de casa es requerido.', 'home');
      this.validate(this.validar, this.resident.name, 'El Nombre del residente es requerido.', 'name');
      this.validate(this.validar, this.resident.lastName, 'El Apellido del residente es requerido.', 'lastname');
      this.validate(this.validar, this.resident.phone, 'El Celular de contacto del residente es requerido.', 'phone');
      this.validate(this.validar, this.resident.phone.length === 10, 'El Celular debe tener mínimo 10 números.', 'phone');
    }
    return this.validar;
  }

  validate(validate, condition, msg, id) {
    if (validate) {
      if (!condition) {
        this.validar = false;
        this.fireToast(msg);
        this.showField(id);
        return false;
      }
    }
  }

  fireToast(msg) {
    Swal.fire('Confirmación', msg, 'info');
  }

  showField(id) {
    const element = document.getElementById(id);
    const properties = element.getBoundingClientRect();
    window.scrollTo({
      top: properties.y,
      behavior: 'smooth'
    });
    document.getElementById(id).focus();
  }

  updateResident() {
    if (!this.validateResident()) {
      return;
    }
    const loading = loadingFireToast('Actualizando información, por favor espere...');
    this.apiService.post('residente/update', this.resident).subscribe(
      (res) => {
        this.update = true;
        loading.close();
        Swal
          .fire({
            icon: 'success',
            title: 'Exito',
            text: 'Gracias por actualizar sus datos, se han guardado correctamente, ¡Bienvenido a Veciino!.'
          });
      },
      (error) => {
        loading.close();
        Swal
          .fire({
            icon: 'info',
            title: 'Advertencia',
            text: 'Ocurrió un error actualizando los datos de contacto',
            timer: 2000,
          });
        console.log('error actualizando la data del residente', error);
      }
    )
  }
}
